import mediaQueries from 'util/mediaqueries'
import { typography } from 'util/styles-mixins'
import { fontFamily, fontSize } from 'constants/typography'

import styled, { css } from 'styled-components'

// Boilerplate for creating typography components
export const Typography = styled.p`
  margin: 0;

  ${({
    size = '',
    extraSmallViewportsSize = '',
    smallViewportsSize = '',
    mediumViewportsSize = '',
    largeViewportsSize = '',
    extraLargeViewportsSize = '',
    fontSizes = {},
    letterSpacings = {},
    lineHeights = {},
    lineClamp = 'unset',
  }) => css`
    font-size: ${fontSizes[extraSmallViewportsSize ?? size]};
    letter-spacing: ${letterSpacings[extraSmallViewportsSize ?? size]};
    line-height: ${lineHeights[extraSmallViewportsSize ?? size]};

    ${typography.lineClamp(lineClamp)}

    ${mediaQueries.sm`
      font-size: ${fontSizes[smallViewportsSize ?? size]};
      letter-spacing: ${letterSpacings[smallViewportsSize ?? size]};
      line-height: ${lineHeights[smallViewportsSize ?? size]};
    `}

    ${mediaQueries.md`
      font-size: ${fontSizes[mediumViewportsSize ?? size]};
      letter-spacing: ${letterSpacings[mediumViewportsSize ?? size]};
      line-height: ${lineHeights[mediumViewportsSize ?? size]};
    `}

    ${mediaQueries.lg`
      font-size: ${fontSizes[largeViewportsSize ?? size]};
      letter-spacing: ${letterSpacings[largeViewportsSize ?? size]};
      line-height: ${lineHeights[largeViewportsSize ?? size]};
    `}

    ${mediaQueries.xl`
      font-size: ${fontSizes[extraLargeViewportsSize ?? size]};
      letter-spacing: ${letterSpacings[extraLargeViewportsSize ?? size]};
      line-height: ${lineHeights[extraLargeViewportsSize ?? size]};
    `}
  `}
`

export const ExampleContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const Eyebrow = styled.p`
  margin: 0;
  font-family: ${fontFamily.apercuMonoPro};
  font-size: ${fontSize.xSmall};
  letter-spacing: 0.07em;
  line-height: 1.1;
  text-transform: uppercase;
`
