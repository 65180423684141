import { css } from 'styled-components'

export const typography = {
  lineClamp: (numLines: 'unset' | number = 1) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${numLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
}
