import { fontWeight } from 'constants/typography'

import styled from 'styled-components'
import { Typography as BaseTypography } from 'components/typography/typography.styles'

export const Typography = styled(BaseTypography)`
  font-weight: ${({ emphasised }) =>
    emphasised ? fontWeight.bold : fontWeight.regular};
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
`
