import PropTypes from 'prop-types'

export const SIZE = {
  XXS: 'xxSmall',
  XS: 'xSmall',
  S: 'small',
  M: 'medium',
  L: 'large',
}

export const SIZES = Object.values(SIZE)

export const FONT_SIZE = {
  [SIZE.XXS]: '12px',
  [SIZE.XS]: '14px',
  [SIZE.S]: '16px',
  [SIZE.M]: '20px',
  [SIZE.L]: '24px',
}

export const LETTER_SPACING = {
  [SIZE.XXS]: 'normal',
  [SIZE.XS]: 'normal',
  [SIZE.S]: 'normal',
  [SIZE.M]: '-0.01em',
  [SIZE.L]: '-0.01em',
}

export const LINE_HEIGHT = {
  [SIZE.XXS]: '1.3',
  [SIZE.XS]: '1.3',
  [SIZE.S]: '1.4',
  [SIZE.M]: '1.2',
  [SIZE.L]: '1.2',
}

export const propTypes = {
  children: PropTypes.node,
  element: PropTypes.oneOfType([
    PropTypes.oneOf(['p', 'span', 'a']),
    PropTypes.elementType,
  ]),
  size: PropTypes.oneOf(SIZES),
  extraSmallViewportsSize: PropTypes.oneOf(SIZES),
  smallViewportsSize: PropTypes.oneOf(SIZES),
  mediumViewportsSize: PropTypes.oneOf(SIZES),
  largeViewportsSize: PropTypes.oneOf(SIZES),
  extraLargeViewportsSize: PropTypes.oneOf(SIZES),
  emphasised: PropTypes.bool,
  underlined: PropTypes.bool,
}

export const defaultProps = {
  element: 'p',
  size: SIZE.M,
  extraSmallViewportsSize: null,
  smallViewportsSize: null,
  mediumViewportsSize: null,
  largeViewportsSize: null,
  extraLargeViewportsSize: null,
  emphasised: false,
  underlined: false,
}
