import { memo } from 'react'

import {
  defaultProps,
  propTypes,
  FONT_SIZE,
  LETTER_SPACING,
  LINE_HEIGHT,
} from './constants'
import { Typography } from './text.styles'

export const Text = ({ element = defaultProps.element, ...props }) => {
  return (
    <Typography
      as={element}
      {...props}
      fontSizes={FONT_SIZE}
      letterSpacings={LETTER_SPACING}
      lineHeights={LINE_HEIGHT}
    />
  )
}

Text.propTypes = propTypes

Text.defaultProps = defaultProps

export default memo(Text)
